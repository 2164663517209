import React, { useEffect, useState } from 'react';
import { getCookie } from '~/services/cookies.client';

interface GclidContainerProps {
  link: string;
  onClick?: () => void;
  children: React.ReactElement<{ onClick: () => void }>;
}

const TypeFormLinkContainer = ({ link, onClick, children }: GclidContainerProps) => {
  const [gclid, setGclid] = useState<string | null>(null);
  const [utmSource, setUtmSource] = useState<string | null>(null);

  const searchParams = new URLSearchParams();

  searchParams.set('typeform-source', 'www.fiveoffices.com');
  if (gclid) searchParams.set('gclid', gclid);
  if (utmSource) searchParams.set('utm_source', utmSource);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    window.open(link + '?' + searchParams.toString(), '_blank');
  };

  useEffect(() => {
    const gclidCookie = getCookie('gclid');
    const utmSourceCookie = getCookie('utm_source');
    if (gclidCookie) setGclid(gclidCookie);
    if (utmSourceCookie) setUtmSource(utmSourceCookie);
  }, []);

  return React.cloneElement(children, { onClick: handleClick });
};

export default TypeFormLinkContainer;
